import { formatFileName } from './formatData';

const databaseUrl = process.env.REACT_APP_DATABASE_URL;
const publicBooksFolder = process.env.REACT_APP_PUBLIC_BOOKS_FOLDER;
const wpPublicBooksFolder = process.env.REACT_APP_WP_PUBLIC_BOOKS_FOLDER;

export function getAuthorImgSrc(authorImg) {
  if (authorImg && authorImg.includes('author_images')) {
    const splitAuthorImg = authorImg.split('\\');
    return `https://tiger-database.github.io/${splitAuthorImg[0]}/${splitAuthorImg[1]}`;
  }
  return authorImg;
}

export function getBookCoverSrc(cover) {
  if (cover && cover.includes('book_covers')) {
    const splitCover = cover.split('\\');
    return `https://tiger-database.github.io/${splitCover[0]}/${splitCover[1]}`;
  }
  return cover;
}

export function getBookImageSrc(image) {
  if (image && image.includes('book_images')) {
    const splitImage = image.split('\\');
    return `https://tiger-database.github.io/${splitImage[0]}/${splitImage[1]}/${splitImage[2]}`;
  }
  return image;
}

export function getBookDataSrc(mainCategory, subcategory) {
  if (formatFileName(mainCategory) === 'wattpad') {
    return `${databaseUrl}/${wpPublicBooksFolder}/${formatFileName(subcategory)}.json`;
  }
  return `${databaseUrl}/${publicBooksFolder}/${formatFileName(mainCategory)}.json`;
}

export function getCurrentFeaturedSrc() {
  return `${databaseUrl}/${publicBooksFolder}/specials.json`;
}

export function getFeaturedBooksSrc() {
  return `${databaseUrl}/${publicBooksFolder}/en-vedette.json`;
}

export function getMainCategoriesSrc() {
  return `${databaseUrl}/${publicBooksFolder}/mainCategories.json`;
}

export function getSubcategoriesSrc() {
  return `${databaseUrl}/${publicBooksFolder}/subcategories.json`;
}
