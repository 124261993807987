export default {
  adultContentAccept: 'Afficher le contenu destiné aux adultes',
  adultContentMessage1: "Le contenu auquel vous essayez d'accéder peut inclure des éléments sexuellement explicites et s'adresse à un public adulte. En accédant à ce contenu, vous confirmez que vous êtes majeur selon la loi en vigueur pour consulter des contenus sexuellement explicites, et que vous vous engagez à ne pas le partager avec des mineurs ou des personnes non autorisées à y accéder.",
  adultContentMessage2: "En cliquant sur «Afficher le contenu destiné aux adultes», vous acceptez d'assumer l'entière responsabilité de votre visionnage. Si vous n'êtes pas en âge légal ou si vous préférez ne pas consulter ce contenu, veuillez cliquer sur «Annuler».",
  adultCoverClickToDisplay: 'Cliquer pour afficher',
  bookFormLabelKeywords: 'Mots-clés (séparés par une virgule):',
  bookFormLabelMainCategory: 'Catégorie principale:',
  bookFormLabelNbPages: 'Nombre de pages:',
  bookFormLabelSubcategory: 'Sous-catégorie:',
  bookFormLabelYears: 'Année de publication:',
  bookFormText1: 'Débutez votre exploration avec une catégorie:',
  bookFormText2: 'Sélectionnez ensuite une sous-catégorie:',
  bookFormText3: 'Vous pouvez aussi ajouter des filtres facultatifs.',
  bookHeaderAudience: 'Public cible',
  bookHeaderAuthorBio: "À propos de l'auteur",
  bookHeaderCollection: 'Collection',
  bookHeaderDimensions: 'Dimensions',
  bookHeaderLanguage: 'Langue',
  bookHeaderNbPages: 'Impression',
  bookHeaderPublisher: 'Éditeur',
  bookHeaderReleaseDate: 'Date de parution',
  bookHeaderSellers: 'Voir sur',
  bookHeaderSocial: "Suivez l'auteur!",
  bookLinkCopied: 'Lien du livre copié!',
  bookReadAnExcerpt: 'Feuilleter un extrait',
  fileLikedBooks: 'mes-livres-aimés',
  filterHeader: 'Filtre de recherche',
  footer1: 'Propulsé avec du cœur ❤️, du café ☕ et par ',
  footer2: 'Un Simple Tigre.',
  generalAttention: 'Attention',
  generalCancel: 'Annuler',
  generalDelete: 'Supprimer',
  generalDownload: 'Télécharger',
  generalNoOptions: 'Aucune option disponible',
  generalUpload: 'Charger',
  homeFooter1: 'Propulsé par ',
  homeFooter2: 'Un Simple Tigre',
  homeSubheader11: 'Trouve ton',
  homeSubheader12: 'match litteraire!',
  homeSubheader21: 'Le savoir,',
  homeSubheader22: "c'est le pouvoir!",
  homeSubheader31: 'Quel est ton',
  homeSubheader32: 'crush litteraire?',
  homeSubheader41: 'Ta passion, ton livre,',
  homeSubheader42: 'ton univers!',
  homeSubheader51: 'Chasse au',
  homeSubheader52: 'tresor litteraire!',
  homeSubheader61: 'Ouvre un livre,',
  homeSubheader62: 'eveille des passions!',
  homeSubheader71: "Trouve l'amour",
  homeSubheader72: 'entre les lignes!',
  inputMax: 'max',
  inputMin: 'min',
  inputSelect: 'Sélectionnez...',
  likesHeader: 'Livres aimés',
  likesInfo: 'Les livres aimés resteront sauvegardés dans la cache, même si vous fermez la page ou le navigateur.',
  likesQuestionDeletion: 'Êtes-vous sûr de vouloir supprimer ce livre?',
  noResultSearch: 'Aucun résultat trouvé.',
  noResultSliding: "L'étagère est vide.",
  noResultEnd: 'Veuillez relancer une recherche.',
  pageNotFound: 'Oups! Je ne trouve pas la page que vous cherchez.',
  pages: 'pages',
  seeLess: 'En voir moins',
  seeMore: 'En voir plus',
  tooltipBack: 'Retour',
  tooltipDislike: 'Jeter',
  tooltipHome: 'Accueil',
  tooltipLike: "J'aime",
  tooltipSearch: 'Rechercher',
  tooltipShare: 'Partager',
  websiteName: 'Bookyverse',
  ratingAccordingTo: "D'après les internautes de",
  ratingWebsite: 'Quialu.ca',
};
