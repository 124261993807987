import React from 'react';
import PropTypes from 'prop-types';

import { imgLogoWattpadFull } from '../../assets/Images';

function SellerLink({ seller = {} }) {
  if (seller.link.includes('www.wattpad')) {
    return (
      <div>
        <a href={seller.link} target="_blank" rel="noreferrer">
          <img
            className="bookCard-seller-logo"
            src={imgLogoWattpadFull}
            alt="Wattpad"
          />
        </a>
      </div>
    );
  }

  return (
    <div>
      <a href={seller.link} target="_blank" rel="noreferrer">
        {seller.title}
      </a>
    </div>
  );
}

SellerLink.propTypes = {
  seller: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default SellerLink;
