import React from 'react';

import './adultCover.css';

function MiniAdultCover() {
  return (
    <div className="cover-censored-mini">
      <p>18+</p>
    </div>
  );
}

export default MiniAdultCover;
